window.dataLayer = window.dataLayer || [];

if (window.location.hostname.includes('app.luggagehero.com')) {
  // <!-- Google Tag Manager -->
  !(function () {
    'use strict';
    function l(e) {
      for (var t = e, r = 0, n = document.cookie.split(';'); r < n.length; r++) {
        var o = n[r].split('=');
        if (o[0].trim() === t) return o[1];
      }
    }
    function s(e) {
      return localStorage.getItem(e);
    }
    function u(e) {
      return window[e];
    }
    function A(e, t) {
      e = document.querySelector(e);
      return t ? (null == e ? void 0 : e.getAttribute(t)) : null == e ? void 0 : e.textContent;
    }
    var e = window,
      t = document,
      r = 'script',
      n = 'dataLayer',
      o = 'https://hxvksgwv.luggagehero.com',
      a = 'https://load.hxvksgwv.luggagehero.com',
      i = '5hxvksgwv',
      c = '25p=aWQ9R1RNLVRTRlI0RDM%3D&page=3',
      g = 'cookie',
      v = 'browser_id',
      E = '',
      d = !1;
    try {
      var d =
          !!g &&
          ((m = navigator.userAgent), !!(m = new RegExp('Version/([0-9._]+)(.*Mobile)?.*Safari.*').exec(m))) &&
          16.4 <= parseFloat(m[1]),
        f = 'stapeUserId' === g,
        I =
          d && !f
            ? (function (e, t, r) {
                void 0 === t && (t = '');
                var n = { cookie: l, localStorage: s, jsVariable: u, cssSelector: A },
                  t = Array.isArray(t) ? t : [t];
                if (e && n[e])
                  for (var o = n[e], a = 0, i = t; a < i.length; a++) {
                    var c = i[a],
                      c = r ? o(c, r) : o(c);
                    if (c) return c;
                  }
                else console.warn('invalid uid source', e);
              })(g, v, E)
            : void 0;
      d = d && (!!I || f);
    } catch (e) {
      console.error(e);
    }
    var m = e,
      g =
        ((m[n] = m[n] || []),
        m[n].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }),
        t.getElementsByTagName(r)[0]),
      v = I ? '&bi=' + encodeURIComponent(I) : '',
      E = t.createElement(r),
      f = (d && (i = 8 < i.length ? i.replace(/([a-z]{8}$)/, 'kp$1') : 'kp' + i), !d && a ? a : o);
    (E.async = !0), (E.src = f + '/' + i + '.js?' + c + v), null != (e = g.parentNode) && e.insertBefore(E, g);
  })();

  // Hotjar Tracking Code for luggagehero.com
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 645692, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
} else if (window.location.hostname.includes('app.devheroo.com')) {
  // Load staging scripts here
}
